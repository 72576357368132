import React from "react";
import { SvgWrapper } from "./CaretDown";

const CaretUpIcon = () => (
  <SvgWrapper>
    <svg
      width="12px"
      height="7px"
      viewBox="0 0 12 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon/Caret - Up</title>
      <g
       stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          transform="translate(-6.000000, -8.000000)"
          fill="#00333D"
        >
          <polygon
            transform="translate(12.000000, 11.500000) scale(1, -1) translate(-12.000000, -11.500000) "
            points="6 8 12 15 18 8"
          ></polygon>
        </g>
      </g>
    </svg>
  </SvgWrapper>
);

export default CaretUpIcon;
