/* This is the project stylesheet */

export default {
  colors: {
    greenBackground: "rgba(230,243,219,1)",
    lightGreenBackground: "rgba(36, 168, 102, 0.1)",
    greenTransparent4: "rgba(1,50,61,0.04)",
    greyBackground: "rgba(245,247,247,1)",
    navyPrimary: "rgba(0,50,61,1)",
    green: "rgb(36, 168, 102)",
    greenTransparent: "rgb(36, 168, 102, 0.1)",
    navyBackgroundHover: "rgba(35, 92, 101, 1)",
    navyBackgroundPressed: "rgba(93, 133, 140, 1)",
    lightButtonHover: "#D8E1E3",
    lightButtonPressed: "#235C65",
    boxShadow: "rgba(0, 0, 0, 0.16)",
    whiteSecondary: "rgba(250, 251, 252, 1)",
    navyTransparent: "rgba(0,51,61,0.15)",
    buttonBorder: "rgba(0, 51, 63, 1)",
  },
  textColors: {
    greenPrimary: "rgba(37,168,102,1)",
    white: "rgba(255,255,255,1)",
    grey: "rgba(0, 51, 61, 1)",
    red: "rgba(208, 2, 27, 1)",
  },
  fonts: {
    buttonLeftBlack: {
      family: "Agenda",
      size: "20px",
      weight: "400",
      lineHeight: "24px",
    },
    buttonLeftBlackSm: {
      family: "Agenda",
      size: "16px",
      weight: "400",
      lineHeight: "24px",
    },
    buttonLeftWhite: {
      family: "Agenda",
      size: "18px",
      weight: "400",
      lineHeight: "24px",
    },
    desktop1HeadingXxlBlackDefaultCentre: {
      family: "Agenda",
      size: "48px",
      weight: "400",
      lineHeight: "60px",
    },
    desktop2HeadingXlBlackDefaultLeft: {
      family: "Agenda",
      size: "39px",
      weight: "400",
      lineHeight: "48px",
    },
    desktop4HeadingMBlackDefaultLeft: {
      family: "Agenda",
      size: "25px",
      weight: "400",
      lineHeight: "32px",
    },
    desktopTextLargeBlackDefaultLeft: {
      family: "Helvetica",
      size: "20px",
      weight: "400",
      lineHeight: "28px",
    },
    formFieldInput: {
      family: "Helvetica",
      size: "18px",
      weight: "400",
      lineHeight: "24px",
    },
    formFieldPlaceholder: {
      family: "Helvetica",
      size: "16px",
      weight: "400",
      lineHeight: "24px",
    },
  },
};
