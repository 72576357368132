import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { DesktopNavigation } from "./NavigationDesktopComponent";
import theme from "./stylesheet";

import "../../stylesheets/main.scss";
import { FooterNavigation } from "./FooterNavigationComponent";

export default (props) => {
  return (<StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />);
};

const PageWrapper = styled.div`
  ${({ navOpen }) =>
    navOpen &&
    `height: 100vh;
  overflow-y: hidden;`}
  background-color: white;

  @media (max-width: 1200px) {
    padding-top: 80px;
    overflow: visible;
    height: auto;
  }
`;

export const NavContainer = styled.div`
  @media (max-width: 1200px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
`;

const Layout = (props) => {
  const [navOpen, setNavOpen] = useState(false);

  // Define the meta title and description
  const title = props.title;
  const description = props.description;

  // Load the Prismic edit button
  if (typeof window !== "undefined" && window.prismic) {
    window.prismic.setupEditButton();
  } 

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
          rel="stylesheet"
          type="text/css"
        ></link>
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        ></link>
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css"
          rel="stylesheet"
        ></link>
        <link
          rel="stylesheet"
          href="https://use.typekit.net/ets2mmv.css"
        ></link>
        <link rel='canonical' href={props.canonicalUrl ?? "/"}/>
      </Helmet>
      <PageWrapper navOpen={navOpen}>
        <NavContainer>
          <DesktopNavigation
            id="navigationBar"
            setNavOpen={setNavOpen}
            navOpen={navOpen}
            hideNavMenu={props.hideNavMenu}
          />
        </NavContainer>
        <BodyWrapper>{props.children}</BodyWrapper>
        <FooterNavigation />
      </PageWrapper>
    </ThemeProvider>
  );
};

const BodyWrapper = styled.div`
  padding-top: 0px;
  @media only screen and (min-width: 1200px) {
    padding-top: 79px;
  }
`;
