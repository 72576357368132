export const routeHelper = (url) => {
  if (url?.includes("#formPanel")) {
    //In prismic, #formPanel is used thoroughly as a way to link to the contact form. If they have used #formPanel on a page as a link, we want to
    //search to see if contact form exists. If so, we want to scroll to it in this window. If the contact form is not on the page,
    //then we will open the homepage and scroll to it's contact form.
    if (typeof window !== "undefined") {
      const elementExists = document.getElementById("contact-form");
      if (elementExists !== null) {
        return "#contact-form";
      } else return "https://debatalk.com.au/#contact-form";
    }

    return "/#contact-form";
  }

  if (url?.includes("home")) return "/";
  return url;
};

export const canonicalUrlResolver = (url) => {
  const prodUrl = "https://debatalk.com.au";

  if(url) {
    return `${prodUrl}${url}`;
  }

  return prodUrl;
}