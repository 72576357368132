export const HeaderTypes = {
  navItem: "navigation_item",
  navButtonItem: "navigation_button",
};

export const sliceComponents = {
  heroImage: "hero_image",
  quote: "quote",
  richText: "text",
  imageCaption: "image_caption",
  videoPanel: "video_panel",
  cardPanel: "card_panel",
  callToAction: "call_to_action",
  frequentlyAskedQuestion: "frequent_asked_question",
  pullQuote: "pull_quote",
  imagePanel: "image_panel",
  eoi: "expression_of_interest",
  relatedPage: "related_pages",
  cardForm: "card_form"
};

export const pageTypes = {
  blog: "PRISMIC_Blog",
};
