import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { routeHelper } from "../../../utils/routeHelper";
import { useState, useEffect } from "react";

const IconButton = ({ title, href, icon, theme }) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(routeHelper(href));
  }, []);

  return (
    <ButtonWrapper
      href={`${url}`}
      colorMode={theme}
      className="d-flex flex-row align-items-center justify-content-space-between"
    >
      <IconWrapper>
        <ButtonIcon alt="assetLogo" src={icon} />
      </IconWrapper>
      <ButtonText colorMode={theme}>{RichText.asText(title)} </ButtonText>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.a`
  white-space: nowrap;
  border-radius: 4px;
  padding: 10px 15px 11px 11px;

  border: 1px solid rgba(0, 51, 63, 1);
  text-decoration: none;

  &:not(:last-of-type) {
    margin-right: 24px;
    @media only screen and (max-width: 1200px) {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }

  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case "Light":
        return "rgba(255, 255, 255, 1)";
      case "Dark":
      default:
        return theme.colors.navyPrimary;
    }
  }};
  :hover {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case "Light":
          return theme.colors.lightButtonHover;
        case "Dark":
        default:
          return theme.colors.navyBackgroundHover;
      }
    }};
  }
  :active {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case "Light":
          return theme.colors.lightButtonPressed;
        case "Dark":
        default:
          return theme.colors.navyBackgroundPressed;
      }
    }};
  }
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  ${({ colorMode, theme }) => `
    color:  ${colorMode === "Light" ? theme.colors.navyPrimary : "white"};
  `};
`;

const IconWrapper = styled.div`
  padding-right: 8px;
  display: flex;
  align-items: flex-start;
`;

const ButtonIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export default IconButton;
