import React, { useState } from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import CaretDownIcon from "../../icons/CaretDown";
import CaretUpIcon from "../../icons/CaretUp";
import DropDown from "./HeaderDropdown";
import {
  NavItem,
  NavLink,
  MobileIcon,
  MobileNavigationText,
} from "../NavigationDesktopComponent";
import { routeHelper } from "../../../utils/routeHelper";

const handleTextItemLink = (item) => {
  if (item.primary.navigation_link?._meta?.uid)
    return `/${item.primary.navigation_link?._meta?.uid.replace(/__/g, "/")}`;
  const route = item.primary.navigation_link?.url;
  if (!route) return "/";

  if (route.includes(".")) {
    return route;
  }
  return route.split("https://")[1];
};

const NavTextItem = ({ navigation_item, index, isLast, isDesktop = true }) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  if (isDesktop)
    return (
      <TextWrapper
        className="d-flex flex-row align-items-center position-relative justify-content-space-between "
        isLast={isLast}
        onMouseEnter={() => setDropDownActive(true)}
        onMouseLeave={() => setDropDownActive(false)}
      >
        <HeaderLink
          href={routeHelper(handleTextItemLink(navigation_item))}
          className="float-right"
        >
          {RichText.asText(navigation_item.primary.title)}
        </HeaderLink>
        {dropDownActive && navigation_item.fields.length > 0 && <UpCaret />}
        {!dropDownActive && navigation_item.fields.length > 0 && <DownCaret />}
        {navigation_item.fields.length > 0 && (
          <DropDownWrapper
            className={`${
              dropDownActive ? "d-flex" : "d-none"
            } flex-column position-absolute`}
          >
            <DropDown {...navigation_item} />
          </DropDownWrapper>
        )}
      </TextWrapper>
    );
  return (
    <NavItem
      className="border-bottom"
      onClick={() => setDropDownActive(!dropDownActive)}
    >
      <div className="d-flex">
        <div className="flex-column">
          <div className="d-flex flex-row align-items-center">
            <NavLink href={routeHelper(handleTextItemLink(navigation_item))}>
              <MobileLinkWrapper className="d-flex flex-row align-items-center">
                <MobileIcon
                  alt="assetLogo"
                  src={navigation_item.primary.navigation_icon_link?.url}
                />{" "}
                <MobileNavigationText>
                  {RichText.asText(navigation_item.primary?.title)}
                </MobileNavigationText>
              </MobileLinkWrapper>
            </NavLink>
            {dropDownActive && navigation_item.fields.length > 0 && <UpCaret />}
            {!dropDownActive && navigation_item.fields.length > 0 && (
              <DownCaret />
            )}
          </div>
          {navigation_item.fields.length > 0 && (
            <div
              className={`${
                dropDownActive ? "d-flex" : "d-none"
              } flex-column align-items-start `}
            >
              <DropDown {...navigation_item} isDesktop={false} />
            </div>
          )}
        </div>
      </div>
    </NavItem>
  );
};

const MobileLinkWrapper = styled.div`
  margin-top: 4px;
`;

const TextWrapper = styled.div`
  margin: 0 auto;
  margin-right: ${({ isLast }) => {
    return isLast ? "24px" : "0";
  }};
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const DropDownWrapper = styled.div`
  min-width: 90px;
  top: 16px;
  margin-top: 8px;
  transform: translateX(-10%);
  background-color: white;
  box-shadow: 0px 0px 24px 0px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 4px;
`;

const UpCaret = styled(CaretUpIcon)`
  padding-left: 6px;
  margin-left: 100px;
  align-items: center;
  margin-left: 100px;
`;

const DownCaret = styled(CaretDownIcon)`
  padding-left: 6px;
  align-items: center;
  margin-left: 100px;
`;

const HeaderLink = styled.a`
  text-decoration: none;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  font-family: "Agenda-Semibold";
  margin: 0 0;

  :hover {
    color: ${({ theme }) => theme.textColors.greenPrimary};
  }
`;

export default NavTextItem;
