import React from "react";
import styled from "styled-components";

const CaretDownIcon = () => (
  <SvgWrapper>
    <svg
      width="12px"
      height="7px"
      viewBox="0 0 10 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon/Caret - Down</title>
      <g
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          transform="translate(-1060.000000, -37.000000)"
          fill="#00333D"
        >
          <g transform="translate(56.000000, 16.000000)">
            <g transform="translate(452.000000, 0.000000)">
              <g transform="translate(0.000000, 12.000000)">
                <g transform="translate(547.000000, 2.000000)"
                >
                  <polygon points="5 7.5 10 13.3333333 15 7.5"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </SvgWrapper>
);

export const SvgWrapper = styled.div`
  padding-left: 8px;
  display: inline-block;
`;

export default CaretDownIcon;
